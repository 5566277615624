import React from "react";
import Helmet from "../components/Helmet";
import ProductCollectionBar from "../shopcrate-framework/components/collection/ProductCollectionBar";
import ProductCollectionCarousel from "../shopcrate-framework/components/collection/ProductCollectionCarousel";

function Home() {
    return (
        <React.Fragment>
            <Helmet/>
            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>ShopCrate Demo Shop</h1>
                </div>
            </div>
            <div className="container">
                <ProductCollectionBar collection={ "cartrackers" }/>
                <ProductCollectionBar collection={ "second-collection" }/>

                <div className="row">
                    <div className="col-md-6 mb-3">
                        <ProductCollectionCarousel collection={ "cartrackers" }/>
                    </div>
                    <div className="col-md-6 mb-3">
                        <ProductCollectionCarousel collection={ "second-collection" }/>
                    </div>
                    <div className="col-md-6 mb-3">
                        <ProductCollectionCarousel collection={ "single-product" }/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(Home);
