import React, {
    createContext
} from "react";
import {
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import i18next from "i18next";

import shopcrateApi from "../ShopCrateAPI";
import AddedToCardModal from "../components/AddedToCardModal";
import Countdown from "../components/Countdown";

export const ProductPageContext = createContext(null);

class ProductPageManager extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            context: {
                error: null,
                errorCode: null,
                releaseDate: null,
                product: null,

                showAddedToCartModal: false,
                triggerAddedToCardModal: this.showAddedToCartModal.bind(this),

                getFormattedPrice: this.getFormattedPrice.bind(this)
            }
        }
        this.handleAddedToCardClose = this.handleAddedToCardClose.bind(this);
    }

    setContextState(state) {
        this.setState(previousState => {
            return { context: { ...previousState.context, ...state } }
        });
    }

    componentDidMount() {
        if(this.props.productId) {
            this.getProduct(this.props.productId);
        } else {
            this.getProduct(this.props.match.params.productId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.productId) {
            if(this.props.productId !== prevProps.productId) {
                this.getProduct(this.props.productId);
            }
        } else {
            if(this.props.match.params.productId !== prevProps.match.params.productId) {
                this.getProduct(this.props.match.params.productId);
            }
        }
    }

    getProduct(productId) {
        this.setContextState({ product: null, error: null });
        shopcrateApi.post("/getProduct", { productId })
            .then((response) => {
                if(response.data.valid) {
                    const product = response.data.product;
                    this.setContextState({ product });
                    if(!this.props.productId && product.url !== this.props.match.params.productUrl) {
                        this.props.history.replace("/product/" + this.state.context.product.id + "/" + this.state.context.product.url);
                    }
                } else {
                    this.setContextState({ error: i18next.t("errorGeneral") + " (" + response.data.error + ")", errorCode: response.data.error });
                    if(response.data.error === "NOT_RELEASED_YET") {
                        this.setContextState({ releaseDate: response.data.date });
                    }
                }
            })
            .catch(() => {
                this.setContextState({ error: i18next.t("errorGeneral") });
            })
    }

    getFormattedPrice() {
        if(this.state.context.product == null) {
            return null;
        }
        let price = Math.floor(this.state.context.product.price);
        let cents = Math.round((this.state.context.product.price - price) * 100);
        if(cents > 0 && cents < 10) {
            return {
                price: price,
                cents: `0${cents}`
            }
        } else if(cents !== 0) {
            return {
                price: price,
                cents: cents
            }
        } else {
            return {
                price: price,
                cents: "-"
            }
        }
    }

    showAddedToCartModal() {
        this.setContextState({ showAddedToCartModal: true });
    }

    handleAddedToCardClose() {
        this.setContextState({ showAddedToCartModal: false });
    }

    render() {
        return (
            <ProductPageContext.Provider value={ this.state.context }>
                { this.state.context.error ? (
                    <React.Fragment>
                        { this.props.error404Page && (this.state.context.errorCode === "DOESNT_EXIST" || this.state.context.errorCode === "INVALID_PARAMETERS") ? (
                            <React.Fragment>
                                { this.props.error404Page }
                            </React.Fragment>
                        ) : this.state.context.releaseDate && (this.state.context.errorCode === "NOT_RELEASED_YET") ? (
                            <div className="container my-5 text-center d-flex flex-grow-1 align-items-center">
                                <div className="d-flex w-100 h-100 align-items-center" style={{ minHeight: "380px" }}>
                                    <h1 className="text-white w-100">
                                        <Countdown
                                            date={ this.state.context.releaseDate }
                                        />
                                    </h1>
                                </div>
                            </div>
                        ) : (
                            <div className="container mt-5">
                                <Alert variant="danger">{ this.state.context.error }</Alert>
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        { this.props.children }
                        { this.state.context.product && (
                            <AddedToCardModal
                                show={ this.state.context.showAddedToCartModal }
                                handleClose={ this.handleAddedToCardClose }
                                product={ this.state.context.product }
                            />
                        )}
                    </React.Fragment>
                )}
            </ProductPageContext.Provider>
        )
    }
}

export default withRouter(ProductPageManager);
