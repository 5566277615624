import React, {
    useContext,
    useEffect,
    useState
} from "react";
import {
    Link,
    useHistory
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";

import CartContext from "../context/internal/CartManager";
import OrderContext from "../context/internal/OrderManager";
import ShopContext from "../context/internal/ShopManager";
import Loading from "../components/Loading";
import CartCard from "../components/CartCard";
import CartPriceSummary from "../components/CartPriceSummary";
import CartSelectCountry from "../components/CartSelectCountry";

function CartPageContent() {
    const cartContext = useContext(CartContext);
    const orderContext = useContext(OrderContext);
    const shopContext = useContext(ShopContext);
    const history = useHistory();
    const [error, setError] = useState(null);

    const onError = (newError) => {
        setError(newError);
        window.scroll({ top: 0, behavior: "smooth" });
    }
    const nextStep = () => {
        if(shopContext.shop.shippingCosts && orderContext.deliveryInfo.deliveryCountry.length === 0) {
            onError(i18next.t("errorNoCountrySelected"));
            return;
        }
        history.push("/order/data");
    };

    useEffect(() => {
        if(!shopContext.shop || !shopContext.shop.shippingCosts) {
            return;
        }
        if(orderContext.countries === null) {
            orderContext.getCountries();
        }
    }, [shopContext, orderContext]);

    if(shopContext.error) {
        return (
            <Alert variant="danger">{ shopContext.error }</Alert>
        )
    }

    return (
        <React.Fragment>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            { cartContext.error !== null ? (
                <Alert variant="danger">{ cartContext.error }</Alert>
            ) : orderContext.countriesError !== null ? (
                <Alert variant="danger">{ orderContext.countriesError }</Alert>
            ) : cartContext.cart === null || cartContext.products === null || shopContext.shop === null || orderContext.isLoading() ? (
                <Loading/>
            ) : cartContext.cart.length === 0 ? (
                <div className="text-center">
                    <i className="fas fa-shopping-cart mb-2" style={{ fontSize: "3rem" }}/>
                    <h1>
                        <Trans i18nKey="cartEmptyTitle"/>
                    </h1>
                    <Link to="/" className="btn btn-primary">
                        <Trans i18nKey="cartEmptyButton"/>
                    </Link>
                </div>
            ) : (
                <div className="row">
                    <div className="col-md-9">
                        { cartContext.cart.map((item) => (
                            <CartCard
                                key={ item.productId }
                                product={ cartContext.products.find((product) => product.id === item.productId) }
                                amount={ item.amount }
                                addToCart={ cartContext.addToCart }
                            />
                        )) }
                        <CartSelectCountry/>
                        <CartPriceSummary/>
                    </div>
                    <div className="col-md-3">
                        <Button variant="success" className="pl-4 pr-4 pt-2 pb-2 mr-2" onClick={ nextStep }>
                            <Trans i18nKey="cartOrderButton"/>
                        </Button>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

export default React.memo(CartPageContent);
