import React, {
    useMemo
} from "react";
import {
    Trans
} from "react-i18next";

import CartCard from "../../components/CartCard";
import DigitalProductLinksList from "../../components/order/DigitalProductLinksList";
import OrderCompleteCard from "../../components/order/OrderCompleteCard";

function DefaultOrderSuccessPage({ order }) {
    const links = useMemo(() => {
        if(!order) {
            return null;
        }
        let links = [];
        for(const product of order.products) {
            links = links.concat(product.productLinks);
        }
        return links;
    }, [order]);

    if(!order) {
        return null;
    }

    const productsCard = (
        <div className="card mb-3">
            <div className="card-body">
                <h5 className="card-title">
                    <Trans i18nKey="products"/>
                </h5>
                { order.products.map((product) => (
                    <CartCard
                        key={ product.id }
                        product={ product }
                        amount={ product.amount }
                        hideImage={ order.containsDigitalProducts }
                        noLink
                    />
                ))}
            </div>
        </div>
    );

    if(!order.containsDigitalProducts) {
        return (
            <React.Fragment>
                <OrderCompleteCard
                    order={ order }
                />
                { productsCard }
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-6">
                    <OrderCompleteCard
                        order={ order }
                    />
                </div>
                <div className="col-md-6">
                    { productsCard }
                </div>
            </div>
            <DigitalProductLinksList
                links={ links }
            />
        </React.Fragment>
    );
}

export default React.memo(DefaultOrderSuccessPage);
