import axios from "axios";
import {
    stringify
} from "qs";

if(!process.env.REACT_APP_SHOPCRATE_SHOP_CODENAME) {
    throw new Error("Environment variable REACT_APP_SHOPCRATE_SHOP_CODENAME is undefined.");
}

export const SHOPCRATE_API_URL = process.env.REACT_APP_SHOPCRATE_API_URL ? process.env.REACT_APP_SHOPCRATE_API_URL : "api.shopcrate.nl";
const SHOPCRATE_SHOP_CODENAME = process.env.REACT_APP_SHOPCRATE_SHOP_CODENAME;

const SHOPCRATE_API_BASEURL = (process.env.REACT_APP_ENVIRONMENT === "development" ? "" : "https://" + SHOPCRATE_API_URL) + "/v1/shop/" + SHOPCRATE_SHOP_CODENAME + "/"

const shopcrateApi = axios.create({
    baseURL: SHOPCRATE_API_BASEURL,
    withCredentials: true,
    transformRequest: (data) => {
        data = stringify(data);
        return data;
    }
});

export default shopcrateApi;
