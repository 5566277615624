import React, {
    useRef,
    useState
} from 'react';
import {
    Link,
    useRouteMatch
} from "react-router-dom";
import {
    Container,
    Nav,
    Navbar as RBNavbar,
    NavDropdown
} from "react-bootstrap";
import useOutsideAlerter from "../hooks/OutsideAlerter";
import CartContext from "../shopcrate-framework/context/internal/CartManager";

function NavbarLink({to, title, exact = false, hidden = false, onClick}) {
    let match = useRouteMatch({
        path: to,
        exact: exact
    });
    let className = "nav-item" + (match ? " active" : "") + (hidden ? " d-none" : "");
    return (
        <li className={ className } data-toggle="collapse" data-target=".navbar-collapse.show">
            <Link to={ to } className="nav-link" onClick={ onClick }>
                { title }
            </Link>
        </li>
    );
}

function Navbar() {
    let [showCollapsed, setShowCollapsed] = useState(false);
    let collapse = () => {
        setShowCollapsed(false);
    }

    let [showCartDropdown, setShowCartDropdown] = useState(false);
    let toggleCartDropdown = () => setShowCartDropdown(!showCartDropdown);

    let matchCart = useRouteMatch({
        path: "/cart",
        exact: false
    });

    const cartDropdownRef = useRef(null);
    useOutsideAlerter(cartDropdownRef, () => setShowCartDropdown(false));

    return (
        <React.Fragment>
            <CartContext.Consumer>
                { cart =>
                    <RBNavbar bg="light" expand="lg" className="fixed-top" expanded={ showCollapsed }>
                        <Container>
                            <a className="navbar-brand" href="/">
                                ShopCrate Demo Shop
                            </a>
                            <RBNavbar.Toggle aria-controls="basic-navbar-nav" onClick={ () => setShowCollapsed(!showCollapsed) } />
                            <RBNavbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto"/>
                                <Nav>
                                    <NavbarLink to="/" title="Home" exact onClick={ collapse }/>
                                    <NavbarLink to="/category/2" title="Demo Categorie" onClick={ collapse }/>
                                    <NavDropdown
                                        ref={ cartDropdownRef }
                                        id="cart-dropdown"
                                        active={ matchCart !== null }
                                        show={ showCartDropdown }
                                        onClick={ toggleCartDropdown }
                                        title={
                                            <React.Fragment>
                                                <i className="fas fa-shopping-cart"/>
                                                <span className="badge badge-pill badge-primary ml-2">
                                                    { cart.cart === null ? 0 : cart.cart.length }
                                                </span>
                                            </React.Fragment>
                                        }
                                        alignRight
                                    >
                                        <Link to="/cart" className="dropdown-item">Winkelwagen bekijken</Link>
                                    </NavDropdown>

                                </Nav>
                            </RBNavbar.Collapse>
                        </Container>
                    </RBNavbar>
                }
            </CartContext.Consumer>

            <div style={{ height: "56px" }}/>
        </React.Fragment>
    );
}

export default React.memo(Navbar);
