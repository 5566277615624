import React from "react";
import {
    Trans
} from "react-i18next";

function OrderCompleteCard({ order }) {
    return (
        <div className="card mb-3">
            <div className="card-body text-center">
                <i className="fad fa-box-alt text-primary mb-3" style={{ fontSize: "4rem" }}/>
                <h4>
                    <Trans i18nKey="orderCompleteTitle"/>
                </h4>
                { order.containsDigitalProducts && order.containsPhysicalProducts ? (
                    <p className="mb-0"><Trans i18nKey="orderCompleteDescriptionPhysicalDigital"/></p>
                ) : order.containsPhysicalProducts ? (
                    <p className="mb-0"><Trans i18nKey="orderCompleteDescriptionPhysical"/></p>
                ) : order.containsDigitalProducts && (
                    <p className="mb-0"><Trans i18nKey="orderCompleteDescriptionDigital"/></p>
                )}
            </div>
        </div>
    )
}

export default React.memo(OrderCompleteCard);
