import React, {
    useContext
} from "react";
import {
    Trans
} from "react-i18next";
import CartContext from "../context/internal/CartManager";
import OrderContext from "../context/internal/OrderManager";

function CartPriceSummary() {
    const cart = useContext(CartContext);
    const order = useContext(OrderContext);

    const formatPrice = (price) => {
        return price.toFixed(2).replace(".", ",");
    }

    return (
        <div className="card mb-2">
            <div className="card-body">
                <div className="d-flex justify-content-end text-right mb-2">
                    <div style={{ width: "150px" }}>
                        <b>
                            <Trans i18nKey="shippingCosts"/>
                        </b>
                    </div>
                    <div style={{ width: "150px" }}>
                        <b>
                            { order.shippingPrice === null ? (
                                <Trans i18nKey="selectCountry"/>
                            ) : order.shippingPrice === 0 ? (
                                <Trans i18nKey="free"/>
                            ) : (
                                <span>&euro; { formatPrice(order.shippingPrice) }</span>
                            ) }
                        </b>
                    </div>
                </div>
                <div className="d-flex justify-content-end text-right mb-2 text-muted">
                    <div style={{ width: "150px" }}>
                        <b>
                            <Trans i18nKey="vat"/>
                        </b>
                    </div>
                    <div style={{ width: "150px" }}>
                        <b>&euro; { formatPrice(cart.getTotalTax() + (order.shippingPrice ? order.shippingPrice - order.shippingPrice / 1.21 : 0)) }</b>
                    </div>
                </div>
                <div className="d-flex justify-content-end text-right">
                    <div style={{ width: "150px" }}>
                        <b>
                            <Trans i18nKey="totalPrice"/>
                        </b>
                        <br/>
                        <span className="text-muted">
                            <Trans i18nKey="includingVat"/>
                        </span>
                    </div>
                    <div style={{ width: "150px" }}>
                        <b>&euro; { formatPrice(cart.getTotalPrice() + (order.shippingPrice ? order.shippingPrice : 0)) }</b>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartPriceSummary;
