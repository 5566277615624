import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import {
    Link,
    Redirect,
    useHistory
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";

import CartContext from "../context/internal/CartManager";
import OrderContext from "../context/internal/OrderManager";
import ShopContext from "../context/internal/ShopManager";
import Loading from "../components/Loading";
import SteppedProgressBar from "../components/progressbar/SteppedProgressBar";

function DeliveryInfoPageContent() {
    const cart = useContext(CartContext);
    const order = useContext(OrderContext);
    const shop = useContext(ShopContext);
    const history = useHistory();

    const [error, setError] = useState(null);

    const onError = useMemo(() => {
        return (newError) => {
            setError(newError);
            window.scroll({ top: 0, behavior: "smooth" });
        };
    }, []);
    const toNextStep = useMemo(() => {
        return () => {
            if(!order.isDeliveryInfoValid()) {
                onError(i18next.t("errorFieldInput"));
                return;
            }
            history.push("/order/paymentmethod");
        };
    }, [order, history, onError]);
    useEffect(() => {
        if(order.countries === null) {
            order.getCountries();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const deliveryCountry = useMemo(() => {
        if(!order.countries) {
            return null;
        }
        return order.countries.find((country) => {
            return country.iso === order.deliveryInfo.deliveryCountry
        });
    }, [order.countries, order.deliveryInfo.deliveryCountry]);

    if(order.countriesError) {
        return (
            <Alert variant="danger">{ order.countriesError }</Alert>
        );
    }
    if(cart.cart === null || shop.shop === null || order.isLoading()) {
        return (
            <Loading/>
        );
    }
    if(cart.cart.length <= 0) {
        return (
            <Redirect to="/cart"/>
        );
    }

    return (
        <React.Fragment>
            <div className="mt-2 mb-4">
                <SteppedProgressBar>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="cart"/> } to="/cart" complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="information"/> } to="/order/data" active/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="paymentMethod"/> } to="/order/paymentmethod"/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="overview"/> } to="/order/overview"/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="payment"/> }/>
                </SteppedProgressBar>
            </div>

            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}

            <div className="card mb-3">
                <div className="card-body">
                    <h4>
                        <Trans i18nKey="customer"/>
                    </h4>
                    <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" className="custom-control-input" id="private"
                               name="company"
                               checked={ !order.deliveryInfo.company }
                               onChange={(event) => order.updateDeliveryInfo({company: !event.target.checked})}
                        />
                        <label className="custom-control-label" htmlFor="private">
                            <Trans i18nKey="customerPersonal"/>
                        </label>
                    </div>
                    <div className={ "custom-control custom-radio custom-control-inline" + (order.deliveryInfo.company ? " mb-3" : "") }>
                        <input type="radio" className="custom-control-input" id="company"
                               name="company"
                               checked={ order.deliveryInfo.company }
                               onChange={(event) => order.updateDeliveryInfo({company: event.target.checked})}
                        />
                        <label className="custom-control-label" htmlFor="company">
                            <Trans i18nKey="customerCompany"/>
                        </label>
                    </div>
                    { order.deliveryInfo.company && (
                        <div className="form-group mb-0">
                            <label htmlFor="companyName">
                                <Trans i18nKey="companyName"/>
                            </label>
                            <input type="text" className="form-control" id="companyName" required
                                   value={order.deliveryInfo.companyName}
                                   onChange={(event) => order.updateDeliveryInfo({companyName: event.target.value})}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h4>
                        <Trans i18nKey="deliveryAddress"/>
                    </h4>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="deliveryFirstName">
                                    <Trans i18nKey="firstName"/>
                                </label>
                                <input type="text" className="form-control" id="deliveryFirstName" required
                                       value={order.deliveryInfo.deliveryFirstName}
                                       onChange={(event) => order.updateDeliveryInfo({deliveryFirstName: event.target.value})}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="deliveryLastName">
                                    <Trans i18nKey="lastName"/>
                                </label>
                                <input type="text" className="form-control" id="deliveryLastName" required
                                       value={order.deliveryInfo.deliveryLastName}
                                       onChange={(event) => order.updateDeliveryInfo({deliveryLastName: event.target.value})}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8">
                            <div className="form-group">
                                <label htmlFor="deliveryStreet">
                                    <Trans i18nKey="street"/>
                                </label>
                                <input type="text" className="form-control" id="deliveryStreet" required
                                       value={order.deliveryInfo.deliveryStreet}
                                       onChange={(event) => order.updateDeliveryInfo({deliveryStreet: event.target.value})}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="deliveryHouseNumber">
                                    <Trans i18nKey="houseNumber"/>
                                </label>
                                <input type="text" className="form-control" id="deliveryHouseNumber" required
                                       value={order.deliveryInfo.deliveryHouseNumber}
                                       onChange={(event) => order.updateDeliveryInfo({deliveryHouseNumber: event.target.value})}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className={ "form-group" + (shop.shop && shop.shop.shippingCosts ? "" : " mb-0") }>
                                <label htmlFor="deliveryPostalCode">
                                    <Trans i18nKey="postalCode"/>
                                </label>
                                <input type="text" className="form-control" id="deliveryPostalCode" required
                                       value={order.deliveryInfo.deliveryPostalCode}
                                       onChange={(event) => order.updateDeliveryInfo({deliveryPostalCode: event.target.value})}
                                />
                            </div>
                        </div>
                        <div className="col-8">
                            <div className={ "form-group" + (shop.shop && shop.shop.shippingCosts ? "" : " mb-0") }>
                                <label htmlFor="deliveryCity">
                                    <Trans i18nKey="city"/>
                                </label>
                                <input type="text" className="form-control" id="deliveryCity" required
                                       value={order.deliveryInfo.deliveryCity}
                                       onChange={(event) => order.updateDeliveryInfo({deliveryCity: event.target.value})}
                                />
                            </div>
                        </div>
                    </div>
                    { shop.shop && shop.shop.shippingCosts && (
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group mb-0">
                                    <label htmlFor="deliveryState">
                                        <Trans i18nKey="stateProvince"/>
                                    </label>
                                    <input type="text" className="form-control" id="deliveryState" required
                                           value={order.deliveryInfo.deliveryState}
                                           onChange={(event) => order.updateDeliveryInfo({deliveryState: event.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mb-0">
                                    <label htmlFor="deliveryCountry">
                                        <Trans i18nKey="country"/>
                                    </label>
                                    <select
                                        className="form-control"
                                        id="deliveryCountry"
                                        required
                                        value={order.deliveryInfo.deliveryCountry}
                                        onChange={(event) => order.updateDeliveryInfo({deliveryCountry: event.target.value})}
                                    >
                                        <option value=""/>
                                        { order.countries.map((country) => (
                                            <option value={ country.iso } key={ country.iso }>
                                                { country.nameEnglish }
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h4>
                        <Trans i18nKey="invoiceAddress"/>
                    </h4>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="invoiceEqualsDelivery"
                               checked={ order.deliveryInfo.invoiceEqualsDelivery }
                               onChange={(event) => order.updateDeliveryInfo({invoiceEqualsDelivery: event.target.checked})}
                        />
                        <label className="custom-control-label" htmlFor="invoiceEqualsDelivery">
                            <Trans i18nKey="invoiceEqualsDelivery"/>
                        </label>
                    </div>
                    { order.deliveryInfo.invoiceEqualsDelivery ? (
                        order.deliveryInfo.deliveryStreet && order.deliveryInfo.deliveryHouseNumber
                        && order.deliveryInfo.deliveryPostalCode && order.deliveryInfo.deliveryCity
                        && (!shop.shop.shippingCosts || (order.deliveryInfo.deliveryState && order.deliveryInfo.deliveryCountry)) && (
                            <React.Fragment>
                                <h6 className="mb-0 mt-2"><Trans i18nKey="address"/></h6>
                                <p className="mb-0">
                                    { order.deliveryInfo.deliveryStreet + " " + order.deliveryInfo.deliveryHouseNumber }
                                    <br/>
                                    { order.deliveryInfo.deliveryPostalCode + " " + order.deliveryInfo.deliveryCity }
                                    { shop.shop.shippingCosts && (
                                        <React.Fragment>
                                            <br/>
                                            { order.deliveryInfo.deliveryState + ", " + (deliveryCountry ? deliveryCountry.nameEnglish : "Invalid country") }
                                        </React.Fragment>
                                    )}
                                </p>
                            </React.Fragment>
                        )
                    ) : (
                        <React.Fragment>
                            <div className="row mt-2">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="invoiceFirstName">
                                            <Trans i18nKey="firstName"/>
                                        </label>
                                        <input type="text" className="form-control" id="invoiceFirstName" required
                                               value={order.deliveryInfo.invoiceFirstName}
                                               onChange={(event) => order.updateDeliveryInfo({invoiceFirstName: event.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="invoiceLastName">
                                            <Trans i18nKey="lastName"/>
                                        </label>
                                        <input type="text" className="form-control" id="invoiceLastName" required
                                               value={order.deliveryInfo.invoiceLastName}
                                               onChange={(event) => order.updateDeliveryInfo({invoiceLastName: event.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <div className="form-group">
                                        <label htmlFor="invoiceStreet">
                                            <Trans i18nKey="street"/>
                                        </label>
                                        <input type="text" className="form-control" id="invoiceStreet" required
                                               value={order.deliveryInfo.invoiceStreet}
                                               onChange={(event) => order.updateDeliveryInfo({invoiceStreet: event.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label htmlFor="invoiceHouseNumber">
                                            <Trans i18nKey="houseNumber"/>
                                        </label>
                                        <input type="text" className="form-control" id="invoiceHouseNumber" required
                                               value={order.deliveryInfo.invoiceHouseNumber}
                                               onChange={(event) => order.updateDeliveryInfo({invoiceHouseNumber: event.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <div className={ "form-group" + (shop.shop && shop.shop.shippingCosts ? "" : " mb-0") }>
                                        <label htmlFor="invoicePostalCode">
                                            <Trans i18nKey="postalCode"/>
                                        </label>
                                        <input type="text" className="form-control" id="invoicePostalCode" required
                                               value={order.deliveryInfo.invoicePostalCode}
                                               onChange={(event) => order.updateDeliveryInfo({invoicePostalCode: event.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className={ "form-group" + (shop.shop && shop.shop.shippingCosts ? "" : " mb-0") }>
                                        <label htmlFor="invoiceCity">
                                            <Trans i18nKey="city"/>
                                        </label>
                                        <input type="text" className="form-control" id="invoiceCity" required
                                               value={order.deliveryInfo.invoiceCity}
                                               onChange={(event) => order.updateDeliveryInfo({invoiceCity: event.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                            { shop.shop && shop.shop.shippingCosts && (
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group mb-0">
                                            <label htmlFor="invoiceState">
                                                <Trans i18nKey="stateProvince"/>
                                            </label>
                                            <input type="text" className="form-control" id="invoiceState" required
                                                   value={order.deliveryInfo.invoiceState}
                                                   onChange={(event) => order.updateDeliveryInfo({invoiceState: event.target.value})}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mb-0">
                                            <label htmlFor="invoiceCountry">
                                                <Trans i18nKey="country"/>
                                            </label>
                                            <select
                                                className="form-control"
                                                id="invoiceCountry"
                                                required
                                                value={order.deliveryInfo.invoiceCountry}
                                                onChange={(event) => order.updateDeliveryInfo({invoiceCountry: event.target.value})}
                                            >
                                                <option value=""/>
                                                { order.countries.map((country) => (
                                                    <option value={ country.iso } key={ country.iso }>
                                                        { country.nameEnglish }
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h4>
                        <Trans i18nKey="contactInformation"/>
                    </h4>
                    <div className="form-group">
                        <label htmlFor="email">
                            <Trans i18nKey="email"/>
                        </label>
                        <input type="email" className="form-control" id="email" required
                               value={order.deliveryInfo.email}
                               onChange={(event) => order.updateDeliveryInfo({email: event.target.value})}
                        />
                    </div>
                    <div className="form-group mb-0">
                        <label htmlFor="phoneNumber">
                            <Trans i18nKey="phoneNumber"/>
                        </label>
                        <input type="text" className="form-control" id="phoneNumber" required
                               value={order.deliveryInfo.phoneNumber}
                               onChange={(event) => order.updateDeliveryInfo({phoneNumber: event.target.value})}
                        />
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <Link to="/cart" className="btn btn-link">
                        <i className="fas fa-chevron-left mr-2"/>
                        <Trans i18nKey="previousStep"/>
                    </Link>
                    <div className="float-right">
                        <Button variant="success" onClick={ toNextStep }>
                            <Trans i18nKey="nextStep"/>
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(DeliveryInfoPageContent);
