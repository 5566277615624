import React from "react";
import i18next from "i18next";

import shopcrateApi from "../../ShopCrateAPI";

const ShopContext = React.createContext(null);

export class ShopManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shop: null,
            error: null
        }
    }

    componentDidMount() {
        this.getShop();
    }

    getShop() {
        shopcrateApi.get("/getShop")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ shop: response.data.shop });
                } else {
                    this.setState({ error: i18next.t("errorGeneral") + " (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: i18next.t("errorGeneral") });
            });
    }

    render() {
        return (
            <ShopContext.Provider value={ this.state }>
                {this.props.children}
            </ShopContext.Provider>
        )
    }
}

export function withShopContext(Component) {
    return function contextComponent(props) {
        return (
            <ShopContext.Consumer>
                {context => <Component {...props} shopContext={context} />}
            </ShopContext.Consumer>
        )
    }
}

export default ShopContext;
