import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import moment from "moment";
import "moment/locale/nl";
import {
    SHOPCRATE_LANGUAGE
} from "./shopcrate-framework/ShopCrateLocalization";

import App from "./App";

import * as serviceWorker from "./serviceWorker";

const development = process.env.REACT_APP_ENVIRONMENT === "development";

if(development) {
    console.log("Running in development mode. Sentry error reporting is disabled.");
} else {
    Sentry.init({
        dsn: "https://802d62c11f5a4e9690f027914ef5e243@sentry1.zsnode.com/12",
        autoSessionTracking: true,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        // We recommend adjusting this value in production, or using tracesSampler for finer control
        tracesSampleRate: 1.0,
    });
}

moment.locale(SHOPCRATE_LANGUAGE);

ReactDOM.render((
    <App />
), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
