import React, {
    useContext
} from "react";
import {
    Trans
} from "react-i18next";

import OrderContext from "../context/internal/OrderManager";
import ShopContext from "../context/internal/ShopManager";

function CartSelectCountry() {
    const orderContext = useContext(OrderContext);
    const shopContext = useContext(ShopContext);

    if(!shopContext.shop || !shopContext.shop.shippingCosts) {
        return null;
    }

    return (
        <div className="card mb-2">
            <div className="card-body">
                <div className="d-flex justify-content-end">
                    <div className="form-group mb-0" style={{ maxWidth: "300px" }}>
                        <label htmlFor="deliveryCountry">
                            <Trans i18nKey="country"/>
                        </label>
                        <select
                            className="form-control"
                            id="deliveryCountry"
                            required
                            value={orderContext.deliveryInfo.deliveryCountry}
                            onChange={(event) => orderContext.updateDeliveryInfo({deliveryCountry: event.target.value})}
                        >
                            <option value=""/>
                            { orderContext.countries.map((country) => (
                                <option value={ country.iso } key={ country.iso }>
                                    { country.nameEnglish }
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(CartSelectCountry);
