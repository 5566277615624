import React from "react";
import {
    I18nextProvider
} from "react-i18next";

import {
    ShopManager
} from "./internal/ShopManager";
import {
    CartManager
} from "./internal/CartManager";
import {
    OrderManager
} from "./internal/OrderManager";
import {
    PaymentMethodManager
} from "./internal/PaymentMethodManager";
import ShopCrateLocalization from "../ShopCrateLocalization";

function ShopCrateManager({ children }) {
    return (
        <I18nextProvider i18n={ ShopCrateLocalization }>
            <ShopManager>
                <CartManager>
                    <OrderManager>
                        <PaymentMethodManager>
                            { children }
                        </PaymentMethodManager>
                    </OrderManager>
                </CartManager>
            </ShopManager>
        </I18nextProvider>
    )
}

export default ShopCrateManager;
