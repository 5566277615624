import React from "react";
import {
    Link
} from "react-router-dom";
import i18next from "i18next";
import {
    Trans
} from "react-i18next";

import ProductImage from "./ProductImage";

function CartCard({
    product,
    hideImage,
    noLink,
    amount,
    addToCart
}) {
    const productImage = (
        <div
            className="d-flex justify-content-center align-content-center rounded"
            style={{ height: "120px", backgroundColor: "white" }}
        >
            <ProductImage
                image={ product ? product.images[0] : null }
                size="175x130"
                className="img-fluid rounded"
                alt="Product"
                style={{ maxHeight: "120px" }}
            />
        </div>
    );

    const price = product.orderPrice ? product.orderPrice : product.price;

    return (
        <div className="card mb-2">
            <div className="card-body">
                <div className="row">
                    { !hideImage && (
                        <div className="col-md-3">
                            { noLink ? productImage : (
                                <Link to={ "/product/" + product.id + "/" + product.url }>
                                    { productImage }
                                </Link>
                            )}
                        </div>
                    )}
                    <div className="col">
                        { hideImage ? (
                            <div className="d-flex flex-row align-items-center">
                                <div className="flex-grow-1 mr-3">
                                    <h5 className="m-0">
                                        { noLink ? product.name : (
                                            <Link to={ "/product/" + product.id + "/" + product.url }>
                                                { product.name }
                                            </Link>
                                        )}
                                    </h5>
                                </div>
                                <div>
                                    <b style={{ whiteSpace: "nowrap" }}>
                                        &euro; { (amount * price).toFixed(2).replace(".", ",") }
                                    </b>
                                </div>
                            </div>
                        ) : (
                            <React.Fragment>
                                <h5 className="card-title">
                                    { noLink ? product.name : (
                                        <Link to={ "/product/" + product.id + "/" + product.url }>
                                            { product.name }
                                        </Link>
                                    )}
                                </h5>
                                <span className="float-right">
                                    <b>&euro; { (amount * price).toFixed(2).replace(".", ",") }</b>
                                </span>
                            </React.Fragment>
                        )}
                        { addToCart !== undefined && (
                            <React.Fragment>
                                <div className="btn-toolbar align-items-center">
                                    { !product.digital && (
                                        <div className="btn-group mr-2">
                                            <button className="btn btn-danger" onClick={ () => addToCart(product.id, -1) }>
                                                <i className="fas fa-minus"/>
                                            </button>
                                            <input
                                                type="number" className="form-control" placeholder="Aantal"
                                                style={{ borderRadius: 0, width: "75px", textAlign: "center" }}
                                                value={ amount }
                                                onChange={ (event) => addToCart(product.id, amount - event.target.value) }
                                            />
                                            <button className="btn btn-success" onClick={ () => addToCart(product.id, 1) }>
                                                <i className="fas fa-plus"/>
                                            </button>
                                        </div>
                                    )}
                                    <div className="btn-group">
                                        <button className="btn btn-danger" onClick={ () => addToCart(product.id, -amount) }>
                                            <i className="fas fa-trash"/>
                                        </button>
                                    </div>
                                    { product.digital && (
                                        <span className="badge badge-pill badge-primary ml-2">
                                            <Trans i18nKey="digital"/>
                                        </span>
                                    )}
                                </div>
                                { product.limitPerOrder && amount >= product.limitPerOrder && (
                                    <div className="text-muted mt-2">
                                        { i18next.t("maxPerOrderNotice").replace("{amount}", product.limitPerOrder) }
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(CartCard);
